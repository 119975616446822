<template>
	<div>
		<div class="row box">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.modeles_contrats') }}</h3>

				<CustomTable
					id_table="season_contract_config_modeles"
					ref="table_models"
					:items="models"
					:busy="table_busy_models"
					primaryKey="model_id"
					:columsAdd="modelsAddColumns"
					:externSlotColumns="['default_ca']"
				>
					<template v-slot:[`custom-slot-cell(default_ca)`]="{ data }">
						<b-form-checkbox
							class="custom-control custom-checkbox float-right mr-2"
							v-model="data.default_ca"
							@change="switchDefaultModelCa(data)"
							name="check-default-ca" 
							switch
						>
						</b-form-checkbox>
					</template>
				</CustomTable>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
   	import { EventBus } from "EventBus"
	import Navigation from "@/mixins/Navigation.js"
	import ModelMixin from "@/mixins/Model.js"

	export default {
		name: "ModeleList",
		props: ['season_id'],
		mixins: [Navigation, ModelMixin],
		data () {
			return {
				events_tab: {
					'TableAction::goToAddContractConfigModel': this.addContratConfigModel,
					'TableAction::goToDeleteSeasonModel': this.deleteSeasonModel,
					'TableAction::goToEditContractConfigModel': (lines) => {
						this.goToEditModelContract(lines[0].model_id)
					},
					'TableAction::goToDistributeSeasonModel': (lines) => {
						this.distributeSeasonModel(lines[0].model_id)
					},
				},
				models: [],
				table_busy_models: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {

				this.table_busy_models = true
				await this.loadModeleSeasonContractConfig()
				this.table_busy_models = false
			},
			async loadModeleSeasonContractConfig(){
				
				//Je vais checker les différents modeles de courriers
				//Je récupère l'id du contrat après la synchro
				this.models = await this.loadAllModel(this.season_id, ['season'], 'season_contract_config')
			},
			back_action(){
				// this.$router.push({name: "ContractConfigStallionList", params: {horse_id: this.horse.horse_id}})
			},
			async addContratConfigModel() {
				const model_type = await this.loadModelType(['season'])
				const res = await this.createModel(this.season_id, model_type[0].modeltype_id, 'Modèle global contrat de saillie', 'season')

				if(!res) {
					this.failureToast()
					return
				}

				this.goToEditModelContract(res.model_id)
			},
			goToEditModelContract(model_id) {
				this.$router.push({ 
					name: 'configModel', 
					params: { 
						modelable_id: this.season_id,
						modelable_type: 'season',
						model_id: model_id
					}
				})
			},
			distributeSeasonModel(model_id) {
				this.$router.push({ 
					name: 'distributeSeasonModeleContract', 
					params: { 
						modelable_id: this.season_id,
						modelable_type: 'season',
						model_id: model_id
					}
				})
			},
			async deleteSeasonModel(model) {
				const res = await this.deleteSeasonModelById(model.model_id)
				if(res) {
					this.$refs.table_models.unselectAll()
					EventBus.$emit("TableAction::stopSpin")
					this.init_component()
				}
				else {
					this.failureToast()
				}
			},
			switchDefaultModelCa(model) {
				const to_switch = this.models.find(m => m.model_id == model.model_id)
	
				// Si l'utilisateur veut désactiver le modèle
				if(to_switch.default_ca === true) {
					this.failureToast('toast.contract_ca_default_model')
					this.$refs.table_models.refreshTable()
					return
				}

				to_switch.default_ca = !to_switch.default_ca
				this.setDefaultCaModelSeason(model.model_id, this.season_id)

				this.models.filter(m => m.model_id != model.model_id)
					.forEach(m => m.default_ca = false)

				this.$refs.table_models.refreshTable()
			}
		},
		computed: {
			hasDedicatedCa() {
                return this.$store.state.userAccess.hasDedicatedClientArea
			},
			modelsAddColumns() {
				if(!this.hasDedicatedCa) return []
				return ['default_ca']
			}
		},
		watch: {
			
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			HeaderTab : () => import('@/components/HeaderTab'),
			EditModel : () => import('@/components/Model/Edit')
		}
	}
</script>
